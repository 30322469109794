.flex-right {
  justify-content: flex-end;
  display: flex;
}

.flex-justfity-content-center {
  justify-content: center;
  display: flex;
}

.flex-align-items-center-without-height {
  align-items: center;
  display: flex;
}

.flex-align-items-center {
  align-items: center;
  display: flex;
  height: 100%;
}

.flex-direction-row {
  display: flex;
  flex-direction: row;
}

.flex-flow-wrap {
  flex-flow: wrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-end {
  align-items: flex-end;
}

.text-center {
  text-align: center;
}

.standard-layout {
  background-color: white;
  border-radius: 0.25rem;
  padding: 20px;
}

.content-layout-white {
  background-color: white;
  padding: 30px;
  border-radius: 0.25rem;
}

.green-circle {
  width: 15px;
  height: 15px;
  background-color: #71c21a;
}

.red-circle {
  width: 15px;
  height: 15px;
  background-color: #F46A6A;
}

.circle-with-text {
  align-items: center;
  background-color: #556ee6;
  color: #fff;
  display: flex;
  font-weight: 500;
  justify-content: center;
  height: 2rem;
  width: 2rem;
}

.tab-bar-title {
  font-weight: 600;
  font-size: 14px;
}

.margin-0 {
  margin: 0px !important;
}

.margin-left--8px {
  margin-left: -8px !important; 
}

.margin-left--12px {
  margin-left: -12px !important; 
}

.margin-left--16px {
  margin-left: -16px !important; 
}

.margin-left--20px {
  margin-left: -20px !important; 
}

.margin-left-0 {
  margin-left: 0px !important; 
}

.margin-left-2 {
  margin-left: 2px !important; 
}

.margin-left-4 {
  margin-left: 4px !important; 
}

.margin-left-8 {
  margin-left: 8px !important; 
}

.margin-left-12 {
  margin-left: 12px !important; 
}

.margin-left-16 {
  margin-left: 16px !important; 
}

.margin-left-20 {
  margin-left: 20px !important; 
}

.margin-left-32 {
  margin-left: 32px !important; 
}

.margin-left-36 {
  margin-left: 36px !important; 
}

.margin-left-40 {
  margin-left: 40px !important; 
}

.margin-left-48 {
  margin-left: 48px !important; 
}

.margin-left-72 {
  margin-left: 72px !important; 
}

.margin-right-0 {
  margin-right: 0px !important; 
}

.margin-right-4 {
  margin-right: 4px !important; 
}

.margin-right-8 {
  margin-right: 8px !important; 
}

.margin-right-12 {
  margin-right: 12px !important; 
}

.margin-right-16 {
  margin-right: 16px !important; 
}

.margin-right-20 {
  margin-right: 20px !important; 
}

.margin-right-32 {
  margin-right: 32px !important; 
}

.margin-right-38 {
  margin-right: 38px !important; 
}

.margin-right-60 {
  margin-right: 60px !important; 
}

.margin-right--20px {
  margin-right: -20px !important; 
}

.margin-bottom--8 {
  margin-bottom: -8px !important;
}

.margin-bottom--10 {
  margin-bottom: -10px !important;
}

.margin-bottom--12 {
  margin-bottom: -12px !important;
}

.margin-bottom--16 {
  margin-bottom: -16px !important;
}

.margin-bottom--24 {
  margin-bottom: -24px !important;
}

.margin-bottom--30 {
  margin-bottom: -30px !important;
}

.margin-bottom--40 {
  margin-bottom: -40px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important; 
}

.margin-bottom-half {
  margin-bottom: 0.5px !important; 
}

.margin-bottom-2 {
  margin-bottom: 2px !important; 
}

.margin-bottom-4 {
  margin-bottom: 4px !important; 
}

.margin-bottom-8 {
  margin-bottom: 8px !important; 
}

.margin-bottom-12 {
  margin-bottom: 12px !important; 
}

.margin-bottom-14 {
  margin-bottom: 14px !important; 
}

.margin-bottom-16 {
  margin-bottom: 16px !important; 
}

.margin-bottom-18 {
  margin-bottom: 18px !important; 
}

.margin-bottom-24 {
  margin-bottom: 24px !important; 
}

.margin-bottom-28 {
  margin-bottom: 28px !important; 
}

.margin-bottom-32 {
  margin-bottom: 32px !important;
}

.margin-bottom-1rem {
  margin-bottom: 1rem !important;
}

.margin-bottom--1rem {
  margin-bottom: 0px !important;
}

.margin-top--3 {
  margin-top: -3px !important;
}

.margin-top--5 {
  margin-top: -5px !important;
}

.margin-top--8 {
  margin-top: -8px !important;
}

.margin-top--12 {
  margin-top: -12px !important;
}

.margin-top--16 {
  margin-top: -16px !important;
}

.margin-top--18 {
  margin-top: -18px !important;
}

.margin-top--20 {
  margin-top: -20px !important;
}

.margin-top--24 {
  margin-top: -24px !important;
}

.margin-top--28 {
  margin-top: -28px !important;
}

.margin-top--30 {
  margin-top: -30px !important;
}

.margin-top--34 {
  margin-top: -34px !important;
}

.margin-top--40 {
  margin-top: -40px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-top-3 {
  margin-top: 3px !important;
}

.margin-top-4 {
  margin-top: 4px !important;
}

.margin-top-6 {
  margin-top: 6px !important; 
}

.margin-top-7 {
  margin-top: 7px !important; 
}

.margin-top-8 {
  margin-top: 8px !important; 
}

.margin-top-10 {
  margin-top: 10px !important; 
}

.margin-top-12 {
  margin-top: 12px !important; 
}

.margin-top-14 {
  margin-top: 14px !important; 
}

.margin-top-16 {
  margin-top: 16px !important; 
}

.margin-top-20 {
  margin-top: 20px !important; 
}

.margin-top-24 {
  margin-top: 24px !important; 
}

.margin-top-26 {
  margin-top: 26px !important; 
}

.margin-top-28 {
  margin-top: 28px !important; 
}

.margin-top-32 {
  margin-top: 32px !important; 
}

.margin-top-36 {
  margin-top: 36px !important; 
}

.margin-top-39 {
  margin-top: 39px !important; 
}

.margin-top-42 {
  margin-top: 42px !important; 
}

.margin-top-48 {
  margin-top: 48px !important; 
}

.padding-0 {
  padding: 0px !important; 
}

.padding-1rem {
  padding: 1rem 1rem !important; 
}

.padding-0-75rem {
  padding: 0.75rem 0.75rem !important; 
}

.padding-0-55rem {
  padding: 0.55rem 0.55rem !important; 
}

.padding-0-33rem {
  padding: 0.33rem 0.33rem !important; 
}

.padding-0-25rem {
  padding: 0.25rem 0.25rem !important; 
}

.padding-8px {
  padding: 8px!important; 
}

.padding-2-5px {
  padding: 2.5px!important; 
}

.padding-12px {
  padding: 12px;
}

.padding-16px {
  padding: 16px;
}

.padding-vertical-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding-horizontal-8px {
  padding-left: 8px;
  padding-right: 8px;
}

.padding-horizontal-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-10 {
  padding-top: 20px !important;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-left-16 {
  padding-left: 16px !important;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-right-4 {
  padding-right: 4px !important;
}

.padding-right-8 {
  padding-right: 8px !important;
}

.padding-right-16 {
  padding-right: 16px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-bottom-12 {
  padding-bottom: 12px !important;
}

.borderLine {
  width: '100%';
  margin-left: 10px;
  margin-right: 10px;
  height: 1px;
  background-color: grey;
  opacity: 0.3;
}

.table_border_line {
  width: '100%';
  height: 1px;
  background-color: lightgrey;
  opacity: 0.3;
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.splitLine {
  width: '100%';
  margin-bottom: 8px;
  height: 1px;
  background-color: grey;
  opacity: 0.3;
}

.tab_bar_border {
  border-width: 1px;
  border-top-width: 0px;
  border-color: #ced4da;
  border-style: solid;
  padding: 20px;
}

.tab_bar_border_top_width {
  border-top-width: 1px !important;
}

.tree_view_border {
  border-width: 1px;
  border-color: #ced4da;
  border-style: solid;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 1rem;
}

.model_card_border {
  border-width: 1px;
  border-color: #e6e6eb;
  border-radius: 10px;
  border-style: solid;
}

.error_require_text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f46a6a;
}

.total_label {
  font-size: 11px;
  margin-top: 2px;
  font-weight: 500;
}

.full-width {
  width: 100%;
}

/* Sorting Arrow */
.caret {
  display:inline-block;
  width:0;
  height:0;
  margin-left:4px;
  vertical-align:middle;
  border-top:4px dashed;
  border-top:4px solid\9;
  border-right:4px solid transparent;
  border-left:4px solid transparent
 }

 .dropdown,
 .dropup {
  position:relative;
 }

 .dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
 content:"";
 border-top:0;
 border-bottom:4px dashed;
 border-bottom:4px solid\9;
 color: #ccc;
}
/* Sorting Arrow */

/*Replace Input placeholder color */
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: hsl(0, 0%, 50%);
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: hsl(0, 0%, 50%);
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: hsl(0, 0%, 50%);
}

.invalid-feedback {
  display: block;
}

.fc-day-grid-container.fc-scroller {
  height: auto!important;
  overflow-y: auto;
}

.small-text {
  font-size: 12px;
  color: darkgray;
}

.button-status-height {
  background-color: 'white' !important;
  font-weight: 600 !important;
  font-size: 14px;
  text-align: left;
  border-width: 2px;
}

.btn-success-color {
  color: #34c38f;
  border-color: #34c38f;
  border-width: 0.5px;
  background-color: white;
}

.btn-primary-color {
  color: #556ee6;
  border-color: #556ee6;
  border-width: 0.5px;
  background-color: white;
}

.btn-outline-primary-color {
  color: #556ee6;
  border-color: #556ee6;
}

.btn-outline-primary-color:hover {
  color: #fff;
  background-color: #556ee6;
  border-color: #556ee6;
}

.btn-outline-info-color {
  color: #50a5f1;
  border-color: #50a5f1;
}

.btn-outline-info-color:hover {
  color: #fff;
  background-color: #50a5f1;
  border-color: #50a5f1;
}

.btn-soft-primary{
  background-color: rgba(85, 110, 230, 0.25) !important; 
  color: #556ee6 !important;
}

.primary-color {
  color: #556ee6;
}

.btn-danger-color {
  color: #f46a6a;
  border-color: #f46a6a;
  border-width: 0.5px;
  background-color: white;
}

.btn-disabled-color {
  color: grey;
  border-color: #74788d40 ;
  border-width: 0.5px;
  background-color: white;
}

.btn-disabled-color-Pending {
  color: grey;
  border-width: 0.5px;
  border-color: #74788d40 !important;
  background-color: lightgray !important;
}

.expand-cell-header {
  width : 0px;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #71c21a;
}

.my-modal .modal-dialog {
  width: 80vw;    /* Occupy the 90% of the screen width */
  max-width: 80vw! important;
} 

.appointment-tool-tip-background {
  width: 370px;
  /* margin: -0.45rem -0.75rem;  */
  margin-left: -0.75rem;
  margin-right: -0.80rem;
  margin-top: -0.45rem;
  margin-bottom: -0.55rem;
  /* margin-left: -19.5px; */
  /* margin-top: -10px;
  margin-bottom: -10px; */
  background-color: white; 
  border-radius: 10px;
  z-index: 99999;
}

.appointment-tool-tip-header-background {
  background-color: lightblue;
  padding: 30px;
  border-top-left-radius: 8.5px;
  border-top-right-radius: 8.5px;
  color: white;
  text-align: center;
}

.appointment-tool-tip-header-background-Existing {
  background-color: rgb(252, 178, 20);
  padding: 30px;
  border-top-left-radius: 8.5px;
  border-top-right-radius: 8.5px;
  color: white;
  text-align: center;
}

.appointment-tool-top-content-background {
  padding: 0px 25px 25px;
  color: black;
}

.appointment-font-weight-400 {
  font-weight: 400;
  font-size: 14px;
  color: black;
  text-align: left;
}

.appointment-font-weight-800 {
  font-weight: 800;
  font-size: 14px;
  color: black;
  text-align: left;
}

.tooltip-inner {
  max-width: 370px !important;
  background-color: black !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  color: white;
  border-style: solid;
  border-radius: 10px;
}

.payslip-table-border {
  border: 1px solid #eff2f7;
}

.payslip-table-final-content-border {
  border: solid !important;
  border-width: 1.5px !important;
  border-left-width: 1px !important;
  border-top-width: 1.1px !important;
  border-bottom: 3px double !important;
}

.border-width-0 {
  border-width: 0px !important;
}

.border-right-width-0 {
  border-right-width: 0px !important;
}

.receipt-table-border {
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  background-color: transparent;
  margin: 0;
  color:#f6f6f6;
}

.receipt-table-content {
  max-width: 400px;
  display: block;
  /* margin: 0 auto; */
  padding: 20px;
}

.receipt-table-main {
  border-radius: 7px;
  margin: 0;
  border: none; 
}

.receipt-table-body-content{
  font-size: 14px;
  vertical-align: top;
  border: 3px solid #1d1e3a !important;
  background-color: #fff !important;
}

.receipt-table-body-content-block{
  text-align: center;
  border:none !important;
  padding: 0 0 20px !important;
  border-bottom-width: 0px !important;
}

.recept-table-billing-list{
  border-top-width: 1px;
  border-top-color: #eee;
  border-top-style: solid ;
  margin: 0;
  padding:12px 0 !important;
}

.receipt-table-invoice{
  box-sizing: border-box;
  text-align: left;
  width: 80% !important;
  margin: 40px auto;
}

.receipt-table-invoice-content{
  vertical-align: top;
  text-align: center !important;
  padding: 12px 0 !important;
}

.side-menu-header {
  font-size: 15px !important;
  font-weight: 100 !important;
}

.side-menu-content {
  font-size: 14px !important;
  font-weight: 100 !important;
}

.width-100-percentage {
  width: 100% !important;
}

.width-75-percentage {
  width: 75% !important;
}

.width-50-percentage {
  width: 50% !important;
}

.height-0-percentage {
  height: 0% !important
}

.tooltip.show {
  opacity: 1 !important;
}

.table tr {
  white-space: pre-wrap !important
}

.table td {
  white-space: pre-wrap !important
}

.flex-1 {
  flex: 1 !important;
}

/* Example Styles for React Tags*/
.ReactTags__tags {
  position: relative;
  border:1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  padding:6px;
  line-height: 1.5;
  color: #495057;
}

/* Styles for the input */
.ReactTags__tagInput {
  width: 200px;
  display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: none;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #a1a0a079;
  color: black;
  font-size: 12px;
  display: inline-block;
  padding: 2px !important; 
  margin: 0 5px;
  border-radius: 0.25rem;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

.card-green {
  display: block;
  top: 0px;
  position: relative;
  /* max-width: 262px; */
  background-color: white;
  /* background-color: #f2f8f9; */
  border-radius: 4px;
  /* padding: 32px 24px; 
  margin: 12px;  */
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
}

.card-green:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #cccccc;
  background-color: white;
  cursor: pointer;
}

.card-green:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #00838d;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50%;
  transition: transform 0.15s ease-out;
}

.card-green:hover:before {
  transform: scale(2.15);
}

.card-red {
  display: block;
  top: 0px;
  position: relative;
  /* max-width: 262px; */
  background-color: white;
  /* background-color: #f2f8f9; */
  border-radius: 4px;
  /* padding: 32px 24px; 
  margin: 12px;  */
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
}

.card-red:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #cccccc;
  background-color: white;
  cursor: pointer;
}

.card-red:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #bf1212e2;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50%;
  transition: transform 0.15s ease-out;
}

.card-red:hover:before {
  transform: scale(2.15);
}

.card-blue {
  display: block;
  top: 0px;
  position: relative;
  /* max-width: 262px; */
  background-color: white;
  /* background-color: #f2f8f9; */
  border-radius: 4px;
  /* padding: 32px 24px; 
  margin: 12px;  */
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
}

.card-blue:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #cccccc;
  background-color: white;
  cursor: pointer;
}

.card-blue:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #409ecd;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50%;
  transition: transform 0.15s ease-out;
}

.card-blue:hover:before {
  transform: scale(2.15);
}

.card-yellow {
  display: block;
  top: 0px;
  position: relative;
  /* max-width: 262px; */
  background-color: white;
  /* background-color: #f2f8f9; */
  border-radius: 4px;
  /* padding: 32px 24px; 
  margin: 12px;  */
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
}

.card-yellow:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #cccccc;
  background-color: white;
  cursor: pointer;
}

.card-yellow:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #f1b44c;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50%;
  transition: transform 0.15s ease-out;
}

.card-yellow:hover:before {
  transform: scale(2.15);
}

.credit-badge {
  top: 0px;
  position: relative;
  max-width: 262px;
  border-radius: 4px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
  opacity: 0.8;
}

.credit-badge:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 2px 4px rgba(38, 38, 38, 0.2);
  top: -2px;
  border: 1px solid #bbbbbb;
  opacity: 1;
  cursor: pointer;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: transparent;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.tab-one {
  background-color: white;
  /* background-color: #f2f8f9; */
  border-radius: 4px;
  /* padding: 32px 24px; 
  margin: 12px;  */
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
}

.tab-one:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #cccccc;
  background-color: white;

}


.card-radio {
  background-color: #fff;
  border: 2px solid #f6f6f6;
  border-radius: 0.25rem;
  padding: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.display-none {
  display: none;
}  

.card-radio-input-Service:checked + .card-radio{
  background-color: #556de63a !important;
}
.card-radio-input-Package:checked + .card-radio{
  background-color: #34c38f40 !important;
}

.card-radio-input-Product:checked + .card-radio{
  background-color: #F1B44C40 !important;
}

.card-radio-input-OptionalProducts:checked + .card-radio{
  background-color: #995c0040 !important;
}

.card-radio-input-Promotion:checked + .card-radio{
  background-color: #e83e8c20 !important;
}

.card-radio-input-Disabled + .card-radio{
  background-color: #74788d40 !important;
}

.text-optionalProduct{
  color: #995c00 !important;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}
.btn-current-blink {
  animation: blinker 1.5s linear infinite;
}

.btn-not-current {
  color: grey !important;
  border-color: #74788d40 !important;
  border-width: 2px !important;
  background-color: white !important;
}

.btn-color-Pending {
  color: white;
  border-color: #578ebe;
  border-width: 0.5px;
  background-color: #578ebe;
}

.btn-color-Pending:hover{
  color: white !important;
  border-color: #578ebe !important;
  border-width: 0.5px;
  opacity: 0.8;
  background-color: #578ebe !important;
  animation: none;
}

.btn-current-selected-Pending {
  color: white !important;
  border-color: #578ebe !important;
  border-width: 0.5px;
  background-color: #578ebe !important;
  animation: none;
}

.btn-color-Confirmed {
  color: white;
  border-color: #279c71;
  border-width: 0.5px;
  background-color: #279c71;
}

.btn-color-Confirmed:hover {
  color: white !important;
  border-color: #279c71 !important;
  border-width: 0.5px;
  opacity: 0.8;
  background-color: #279c71 !important;
  animation: none;
}

.btn-current-selected-Confirmed {
  color: white !important;
  border-color: #279c71 !important;
  border-width: 0.5px;
  background-color: #279c71 !important;
  animation: none;
}

.btn-color-InServing {
  color: white;
  border-color: #c653ff;
  border-width: 0.5px;
  background-color: #c653ff;
}

.btn-color-InServing:hover{
  color: white !important;
  border-color: #c653ff !important;
  border-width: 0.5px;
  opacity: 0.8;
  background-color: #c653ff !important;
  animation: none;
}

.btn-current-selected-InServing {
  color: white !important;
  border-color: #c653ff !important;
  border-width: 0.5px;
  background-color: #c653ff !important;
  animation: none;
}

.btn-color-RunningLate {
  color: white;
  border-color: #f3c200;
  border-width: 0.5px;
  background-color: #f3c200;
}

.btn-color-RunningLate:hover{
  color: white !important;
  border-color: #f3c200 !important;
  border-width: 0.5px;
  opacity: 0.8;
  background-color: #f3c200 !important;
  animation: none;
}

.btn-current-selected-RunningLate {
  color: white !important;
  border-color: #f3c200 !important;
  border-width: 0.5px;
  background-color: #f3c200 !important;
  animation: none;
}

.btn-color-NotShow {
  color: white;
  border-color: #e08283;
  border-width: 0.5px;
  background-color: #e08283;
}

.btn-color-NotShow:hover{
  color: white !important;
  border-color: #e08283 !important;
  border-width: 0.5px;
  opacity: 0.8;
  background-color: #e08283 !important;
  animation: none;
}

.btn-current-selected-NotShow {
  color: white !important;
  border-color: #e08283 !important;
  border-width: 0.5px;
  background-color: #e08283 !important;
  animation: none;
}

.btn-color-Completed {
  color: white;
  border-color: #2c3e50;
  border-width: 0.5px;
  background-color: #2c3e50;
}

.btn-color-Completed:hover{
  color: white !important;
  border-color: #2c3e50 !important;
  border-width: 0.5px;
  opacity: 0.8;
  background-color: #2c3e50 !important;
  animation: none;
}

.btn-current-selected-Completed {
  color: white !important;
  border-color: #2c3e50 !important;
  border-width: 0.5px;
  background-color: #2c3e50 !important;
  animation: none;
}

.btn-color-Special {
  color: white;
  border-color: #E7D27C;
  border-width: 0.5px;
  background-color: #E7D27C;
}

.btn-color-Special:hover{
  color: white !important;
  border-color: #E7D27C !important;
  border-width: 0.5px;
  opacity: 0.8;
  background-color: #E7D27C !important;
  animation: none;
}

.btn-current-selected-Special {
  color: white !important;
  border-color: #E7D27C !important;
  border-width: 0.5px;
  background-color: #E7D27C !important;
  animation: none;
}

.btn-color-Arrived {
  color: white;
  border-color: #1a43bf;
  border-width: 0.5px;
  background-color: #1a43bf;
}

.btn-color-Arrived:hover {
  color: white !important;
  border-color: #1a43bf !important;
  border-width: 0.5px;
  opacity: 0.8;
  background-color: #1a43bf !important;
  animation: none;
}

.btn-current-selected-Arrived {
  color: white !important;
  border-color: #1a43bf !important;
  border-width: 0.5px;
  background-color: #1a43bf !important;
  animation: none;
}

.inputWithSuccessColor {
  background-color: #34c38f !important;
  color: white !important;
  font-weight: medium;
}

.scrollButton {
  color: white;
  font-size: 25px;
  padding:8px;
  border-width: 0.5px;
  background-color: #556ee6;
  border-radius: 0.25rem;
}

.scrollButton:hover {
  opacity: 0.8;
}

.form-check-input:disabled {
  background-color: gray;
  border-color: lightgrey;
  border-radius: 0.25rem;
  border-width: 1px;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Light.ttf) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Light.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Light.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Regular.ttf) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Regular.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Regular.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Medium.ttf) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Medium.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Medium.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-SemiBold.ttf) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-SemiBold.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-SemiBold.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Bold.ttf) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Bold.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../scss/custom/fonts/Poppins-Bold.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.has-arrow::after {
  content: "\ea57";
  font-family: boxicons !important;
  display: block;
  float: right;
  transition: transform 0.2s;
  font-size: 1rem;
}

.appointmentSlotHover:hover {
  background-color: #fbceb1;
  align-items: center;
  justify-content: center;
  display: flex;
}

.appointmentSlotHover:hover::after {
  /* background-color: green; */
  font-size: 1rem;
  content: "\ebc0";
  font-family: boxicons !important;
  display: block;
  font-size: 16px;
  text-decoration: none;
}

.fc-event{
  width: 102.5% !important;
  font-size: 13px;
}

.page-link {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: auto !important;
}

.table-border-line {
  width: '100%';
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  height: 1px;
  background-color: lightgrey;
  opacity: 0.3;
}

.table-sub-row {
  --bs-table-bg: rgba(80,165,241,.25) !important;
}

.table-row-exceed-min-stock-level {
  background-color: #e83e8c60;
}

.text-green-color {
  color : rgb(72, 173, 72);
}

.text-red-color {
  color : #f46a6a;
}

.text-dark-green-color {
  color : rgb(110, 207, 50);
}

button.close {
  font-size: 16px !important; 
  padding: 1.75rem 1.75rem;
}

.logoWatermark {
  position: absolute;
  text-align: center;
  width: 100%;
  opacity: 0.05;
  top: 35%;
  pointer-events: none;
}

.logoWatermark img {
  transform: rotate(330deg);
  filter: grayscale(100%);
  max-width: 400px;
}

.labelWaterMark {
  position: fixed;
  text-align: center;
  width: 100%;
  opacity: 0.08;
  top: 45%;
  pointer-events: none;
}

.labelWaterMark label {
  transform: rotate(330deg);
  color: white;
  min-width: 100%;
  font-size: 60px;
}

.label-truncate {
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.tabbar-display-block {
  display: block !important;
}

.env-color-display {
  top: 0px;
  left: 0px;
  padding: 2.5px;
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  color: white;
  z-index: 999999;
  width: 100%;
  pointer-events: none;
  opacity: 0.6;
}

.branch-quantities-tool-tip-background {
  width: 300px;
  /* margin: -0.45rem -0.75rem;  */
  margin-left: -0.75rem;
  margin-right: -0.80rem;
  margin-top: -0.45rem;
  margin-bottom: -0.55rem;
  /* margin-left: -19.5px; */
  /* margin-top: -10px;
  margin-bottom: -10px; */
  background-color: #7a7c8379; 
  border-radius: 10px;
  z-index: 99999;
}

.branch-quantities-tool-tip-header-background {
  background-color: #556ee6;
  padding: 30px;
  border-top-left-radius: 8.5px;
  border-top-right-radius: 8.5px;
  color: white;
  text-align: center;
}

.branch-quantities-tool-top-content-background {
  padding: 0px;
  color: white;
}

.table-pink {
  color: #495057;
  border-color: #eff2f7;
  background-color: pink;
}

.minWidth-250px {
  min-width: 250px;
}

.pointer-clickable {
  position: relative;
  cursor: pointer
}

.pointer-help {
  cursor: help
}

.width-300px {
  width: 300px
}

.row-span-table-border {
  border: dashed #eff2f7;
  border-width: 0.1px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.log-message-view{
  flex: 1;
  padding: 18px;
  background-color: black;
  color: white;
  height: 250px;
  scroll-behavior: smooth; 
  overflow-y: scroll;
}

.table-select-row-support-scroll {
  overflow-x: auto;
  display: flex;
}

.table-select-row-support-scroll-without-flex {
  overflow-x: auto;
}

@page {
  size: 11in 17in;
}

@media print {
  body { 
    -webkit-print-color-adjust: exact; 
  }
  
  #bootstrap-table-sticky-header {
    position: unset;
  }
}

.fc-event {
  border-width: 3px !important; 
}

.bootstrap-table-sticky-header-white-bg {
  position: sticky;
  top: 0;
  background-color: white;
}

.bootstrap-table-sticky-header {
  position: sticky;
  top: 0;
}

.table-sticky-first-column thead tr {
  z-index: 2;
}

.table-sticky-first-column thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}

.table-sticky-first-column tbody td:first-child {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  background: inherit;
}

.customer-tag {
  padding: 2px; 
  padding-left: 4px;
  padding-right: 4px; 
  background-color: #556ee6;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  margin-top: 2px;
  margin-right: 2px;
  max-width: -webkit-fill-available;
}

.customer-tag-hover:hover {
  background-color: #f46a6a;
}

.button-padding-overwrite {
  --bs-btn-padding-x: 0.3rem !important;
  --bs-btn-padding-y: 0.2rem !important;
}

.groupStyles {
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-between';
}

.Toastify__toast-body {
  white-space: pre-line;
}

.z-index-0 {
  z-index: 0;
}

.display-grid {
  display: grid;
}

.align-self-center {
  align-self: center;
}

.input-group > .form-control:not(:focus).is-invalid {
  z-index: 0;
}

.payment-method-sub-text {
  font-size: 11px;
  font-style: italic;
  color: #74788d;
  margin-bottom: 0px !important;
}

.card-radio-border-color-grey {
  border-color: #eeeeee !important;
}

@keyframes fadeShadow {
  0% {
    text-shadow: 1px 1px 1px rgb(178, 34, 34, 0.1);
  }
  50% {
    text-shadow: 1px 1px 1px rgb(178, 34, 34, 0.8);
  }
  100% {
    text-shadow: 1px 1px 1px rgb(178, 34, 34);
  }
}

.shadow-effect {
  color: #FF7F50;
  font-weight: 300;
  text-shadow: none;
  animation: fadeShadow 1s infinite;
}

@layer numberInputRemoveWheel {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.fade-in-animation {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

.transparent-input {
  @media print {
    input {
      color: transparent; /* Makes text invisible */
    }
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.display-ruby {
  display: ruby;
}