// 
// print.scss
//

// Used invoice page
@media print {
    .vertical-menu,
    .right-bar,
    .page-title-box,
    .navbar-header,
    .footer {
        display: none !important;
    }
    // .card-body,
    .main-content,
    .right-bar,
    .table,
    .card
    body {
      padding: 0;
      margin: 0;
    }

    .page-content {
      padding: 0;
      margin: 0;
      background-color: white;
    }

    .card{
      border: 0;
      box-shadow: unset;
    }
}