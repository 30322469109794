.tree-vertical ul {
  overflow-x: auto;
  padding-top: 40px; position: relative;
  margin-left: -20px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;

  display: flex;
}

.tree-vertical li {
  /*overflow-x: auto;*/
  /* overflow: hidden; */
 
  float: left; text-align: center;
  list-style-type: none;
  position: relative;
  padding: 40px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree-vertical li::before, .tree-vertical li::after{
  content: '';
  position: absolute; top: 0; right: 50%;
  border-top: 1px solid lightgray;
  width: 50%; height: 40px;
}
.tree-vertical li::after{
  right: auto; left: 50%;
  border-left: 1px solid lightgray;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree-vertical li:only-child::after, .tree-vertical li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree-vertical li:only-child{ padding-top: 0;}

/*Remove left connector from first child and 
right connector from last child*/
.tree-vertical li:first-child::before, .tree-vertical li:last-child::after{
  border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree-vertical li:last-child::before{
  border-right: 1px solid lightgray;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.tree-vertical li:first-child::after{
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree-vertical ul ul::before{
  content: '';
  position: absolute; top: 0; left: 50%;
  border-left: 1px solid lightgray;
  width: 0; height: 40px;
}

.tree-vertical li a{
  border: 1px solid lightblue;
  background-color: lightblue;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
  font-family: arial, verdana, tahoma;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}